import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useAppContext } from './AppContext';

// StyledTabs component
const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#2e1534',
    },
    '&:hover': {
        color: '#54aeda',
        opacity: 1,
    },
});

// StyledTab component
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(16),
        marginRight: theme.spacing(3), // Increased spacing between tabs
        color: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '8px', // Rounded corners
        '&.Mui-selected': {
            color: '#177bc1',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '&:hover': {
            color: '#177bc1',
            opacity: 1,
        },
    }),
);

const ChillerBar = () => {
    const { API, selectedFamily, setSelectedFamily, setChiller_list, setOpen } = useAppContext();
    const [value, setValue] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [executionid, setExecutionid] = useState('Execution Id Latest');
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedFamily(event.target.innerText); // Update selected family based on tab label
        setExecutionid('Execution Id Latest');
    };

    const handleExecutionidChange = (event, newValue) => {
        setExecutionid(newValue);
    };

    // const handleChillerChange = (event, newValue) => {
    //     setSelectedChiller(newValue);
    // };

    // Sample chiller data
    const sampleExecutionData = [
        'Execution Id Latest',
    ];


    //  =======================================    API  ======================
    useEffect(() => {
        if (selectedFamily !== undefined) {
            get_result_chillers_list();
        }
    }, [selectedFamily]);

    const get_result_chillers_list = async () => {
        setOpen(true);
        console.log('family has changed:', selectedFamily);
        const param = { "family": selectedFamily };
        try {
            const response = await API.getData("/get_result_chillers_list", param);
            console.log("chiller_list response", response);

            if (response) {
                setChiller_list(response);
                console.log("chiller list is been set.", response)
            }
        } catch (error) {
            console.error("Failed to fetch chiller list:", error);
        } finally {
            setOpen(false);
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            position: 'relative'
        }}>
            <div style={{ flexGrow: 1 }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                >
                    <StyledTab label="30RB" />
                    <StyledTab label="30RA" />
                    <StyledTab label="30XA" />
                    <StyledTab label="30XV" />
                    <StyledTab label="HORIZON" />
                    <StyledTab label="30KAV" />
                </StyledTabs>
            </div>

            <div style={{ marginLeft: '1rem' }}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={sampleExecutionData}
                    sx={{ width: 300 }}
                    onChange={handleExecutionidChange}
                    isOptionEqualToValue={(option, value) => option === value || value === ""}
                    value={executionid}
                    renderInput={(params) => <TextField {...params} label="Previous Executions" />}
                />
            </div>

            <div style={{ marginLeft: '1rem' }}>
                {/* <Button
                    style={{ fontSize: "1.4rem", boxShadow: "rgb(106 156 218) 0px 0px 19px 0px" }}
                    variant="contained"
                >
                    Load
                </Button> */}
            </div>
            {/* <div style={{ marginLeft: '1rem' }}>
                <Button
                    style={{
                        padding: "0.5rem",
                        fontSize: "1.3rem",
                        backgroundColor: "green",
                        boxShadow: "rgb(106 156 218) 0px 0px 19px 0px"
                    }}
                    variant="contained"
                    onClick={handleOpenModal}
                >
                    New Execution
                </Button>
            </div> */}


        </div>
    );
};

export default ChillerBar;
