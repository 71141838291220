import React, { useState } from 'react';
import FDDExecutionForm from './FDDExecutionForm';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import Header from '../Header';

const Trigger_App = () => {
    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header name="NEW FDD TRIGGER EXECUTION" />
            <Box sx={{ flex: 1, py: 3, px: 5 }}>
                <div>
                    <FDDExecutionForm/>
                </div>
            </Box>
        </Box>


    );
};

export default Trigger_App;
