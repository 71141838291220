// src/context/AppContext.js
import React, { createContext, useState, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { API } from "../MyApi"
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [selectedFamily, setSelectedFamily] = useState("30RB");
    const [chillers_list, setChiller_list] = useState([]);
    const [selectedChiller, setSelectedChiller] = useState(null);
    const [open, setOpen] = useState(false);

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .MuiTooltip-tooltip`]: {
            fontSize: '1rem', // Larger font size for the tooltip text
        },
    });

    console.log('chillers_list in Appcontext :',chillers_list);
    if (Array.isArray(chillers_list)) {
        console.log('chillers_list is an array.');
    } else {
        console.log('chillers_list is not an array.');
    }
    return (
        <AppContext.Provider value={{
            API,
            setOpen,
            CustomTooltip,
            selectedFamily,
            setSelectedFamily,
            selectedChiller,
            setSelectedChiller,
            chillers_list,
            setChiller_list
        }}>
            <Backdrop
                sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress size="4rem" color="primary" />
            </Backdrop>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
