// FDDWorkflow.jsx
import React from 'react';
import { Box } from '@mui/material';
import Header from '../Header';
import ChillerBar from './ChillerBar';
import Content from './Content';

const Fdd_App = () => (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Header name="FDD WORKFLOW LOGS" />
      <Box
        sx={{
          marginLeft: "2rem",
          marginTop: "1.5rem",
          width: "95%",
          flex: 0,
          py: 0,
          px: 2,
          boxShadow: "rgb(176 202 234) 0px 0px 19px 0px",
          borderRadius: '8px',
        }}
      >
        <ChillerBar />
      </Box>

      <Box sx={{flex: 1, py: 3, px: 5 }}>
        <Content />
      </Box>
    </Box>
);

export default Fdd_App;
