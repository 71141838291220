export const parseS3Keys = (keys) => {
    const items = [];
    keys.forEach(key => {
        const parts = key.split('/');
        let current = items;
        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            let found = current.find(item => item.name === part);
            if (!found) {
                found = {
                    name: part,
                    type: i === parts.length - 1 ? 'file' : 'folder',
                    children: [],
                    fullKey: i === parts.length - 1 ? key : undefined // Store full key for files
                };
                current.push(found);
            }
            current = found.children;
        }
    });
    return items;
};
